body {
  background-color: #fff;
}
.App {
  text-align: center;
}
img {
  max-width: 100%;
}
h1 {
  margin-bottom: 0;
}
h2 {
  margin-bottom: 10px;
}
.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  position: relative;
}
.row,
.row.half {
  margin-left: 0;
  margin-right: 0;
}
.row.middle {
  align-items: center;
}
.row.center {
  justify-content: center;
  text-align: center;
}

.link-view {
  /* background: #1b1b1b; */
  height: auto;
  position: relative;
  width: 100%;
}
.link-view .link-wrap {
  height: auto;
  padding: 40px 1rem;
  transform: translateZ(0);
  z-index: 2;
}
.link-view .link-wrap .link-header .link-header-title {
  padding: 0 2rem;
}
.link-header-title p {
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 0;
}
.link-view .link-wrap .link-container {
  box-shadow: 0 3px 6px rgb(0 0 0 / 12%), 0 3px 6px rgb(0 0 0 / 21%);
  padding: 0;
}

.link-view .link-wrap .link-cover-wrap {
  display: block;
}
.link-view .link-wrap .link-options {
  background: #1e1e1e;
  text-align: left;
  padding-top: 30px;
}
.link-view .link-wrap .link-header {
  background: #1b1b1b;
  color: #fff;
  padding: 1rem 0 0;
  text-align: center;
  white-space: pre-wrap;
}
.link-view .link-wrap .link-options .link-options-header {
  background: #1b1b1b;
  border-bottom: 1px solid #434343;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  position: relative;
  text-align: center;
}
.link-view .link-wrap .link-options .link-option-row {
  border-bottom: 1px solid #434343;
  color: #282828;
  cursor: pointer;
  display: block;
  padding: 1rem 1rem;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.link-view .link-wrap .link-cover-wrap .link-cover {
  background: #121212;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  position: relative;
}
.embed-cover {
  padding: 10px;
  background-color: white;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 10px;
}
.link-view .link-wrap .link-options .link-option-row .link-option-row-img {
  max-height: 30px;
  max-width: 100px;
}
.link-view .link-wrap .link-options .link-option-row .link-option-row-title {
  color: #fff;
  text-align: left;
}
.link-view .link-wrap .link-options .link-option-row .link-option-row-action {
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 10px;
  text-align: right;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  width: 100%;
}
.link-view .link-wrap-bg-layer {
  background: #1b1b1b;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.link-view .link-wrap-bg-layer .link-wrap-bg {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(25px);
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateZ(0);
  width: 100%;
  z-index: 1;
}
.link-view .link-wrap-bg-layer .link-wrap-bg .link-wrap-bg-top {
  background: #1b1b1b;
  height: 50px;
  overflow: auto;
}
